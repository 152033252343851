import { NextPage } from 'next';
import s from '@styles/errorPage.module.scss';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { api } from '@api';
import { PageLayout } from '@components/PageLayout/PageLayout';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { Button } from '@components/UIKit/Button/Button';
import { useTranslation } from '@hooks/useTranslation';
import { useRouter } from 'next/router';
import { LinksEnum } from '@specs/navigation';
interface Props {}
const Error404Page: NextPage<Props> = ({}) => {
  const router = useRouter();
  const {
    translate: tr
  } = useTranslation();
  const utils = api.useContext();
  useEffect(() => () => {
    utils.invalidate();
  }, []);
  const clickHandler = async () => {
    await router.push(LinksEnum.FEED_POSTS);
  };
  const supportHandler = async () => {
    window.open(LinksEnum.SUPPORT, '_blank', 'noreferrer');
  };
  return <PageLayout data-sentry-element="PageLayout" data-sentry-component="Error404Page" data-sentry-source-file="404.tsx">
            <div className={s.errorPage}>
                <div className={cn(s.errorPage__container, '_container')}>
                    <div className={s.errorPage__box}>
                        <div className={s.errorPage__main}>
                            <SpriteIcon className={s.errorPage__logo} name={'logo'} data-sentry-element="SpriteIcon" data-sentry-source-file="404.tsx" />
                            <div className={s.errorPage__code}>
                                {tr({
                id: 'error.page.404.title'
              })}
                            </div>
                            <div className={s.errorPage__text}>
                                {tr({
                id: 'error.page.404.text'
              })}
                            </div>
                            <Button id="go_to_feed_page_404_button" size={'large'} color={'Black'} onClick={clickHandler} data-sentry-element="Button" data-sentry-source-file="404.tsx">
                                {tr({
                id: 'btn.go.to.feed.page'
              })}
                            </Button>
                        </div>
                        <Button id='go_from_404_button' size={'large'} color={'Transparent'} onClick={supportHandler} data-sentry-element="Button" data-sentry-source-file="404.tsx">
                            {tr({
              id: 'error.page.support.button'
            })}
                        </Button>
                    </div>
                </div>
            </div>
        </PageLayout>;
};
export default Error404Page;